import { cn } from "@/lib/utils"

interface PageRootProps {
  children: React.ReactNode
  className?: string
}
export function PageRoot(props: PageRootProps) {
  return (
    <main
      className={cn(
        "min-h-screen mx-auto flex flex-col",
        "max-h-full overflow-hidden",
        "pt-6 sm:pt-24",
        props.className,
      )}
    >
      {props.children}
    </main>
  )
}
