"use client"

import { MaxWidthContainer } from "@/components/max-width-container"
import { PageRoot } from "@/components/page-root"
import { ThemeProvider } from "@/components/theme-provider"
import { cn } from "@/lib/utils"
import { languageTag } from "@/paraglide/runtime"
import { Inter as FontSans } from "next/font/google"

const fontSans = FontSans({
  subsets: ["latin"],
  variable: "--font-sans",
})

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <html lang={languageTag()} suppressHydrationWarning>
      <body
        className={cn(
          "min-h-screen bg-background font-sans antialiased pt-24",
          fontSans.variable,
        )}
      >
        <ThemeProvider attribute="class" enableSystem disableTransitionOnChange>
          <PageRoot>
            <MaxWidthContainer>
              <h2>Something went wrong! {JSON.stringify(error)}</h2>
              <button className="text-white bg-primary" onClick={() => reset()}>
                Try again
              </button>
            </MaxWidthContainer>
          </PageRoot>
        </ThemeProvider>
      </body>
    </html>
  )
}
